import { urls as urls } from '../api/urls.js';
import axios from 'axios';

export function updatePickupLocation(data) {
    return {
        type: 'UPDATE_PICKUP_LOC_MYCHOIZE',
        data
    }
}

export function updateDestLocation(data) {
    return {
        type: 'UPDATE_DEST_LOC_MYCHOIZE',
        data
    }
}

export function addFirstStop(data){
    return {
        type: 'ADD_FIRST_STOP_MYCHOIZE',
        data
    }
}

export function editStop(id,data) {
    
    return {type:'EDIT_STOP_MYCHOIZE',id,data}
}

export function pushStopAtIndex(index,data) {
    
    return {type:'PUSH_STOP_AT_INDEX_MYCHOIZE',index,data}
}

export function addStop(data){
    return {
        type: 'ADD_STOP_MYCHOIZE',
        data
    }
}

export function updateCityOfStop(data,stopId){
    return {
        type: 'UPDATE_CITY_OF_STOP_MYCHOIZE',
        data,
        stopId
    }
}

export function updateDestCity(data) {
    return {
        type: 'UPDATE_DEST_CITY_MYCHOIZE',
        data
    }
}

export function updatePickupCity(data) {
  return {
      type: 'UPDATE_PICKUP_CITY_MYCHOIZE',
      data
  }
}

export function fetchLocationByLatLong(city) {
    return function(dispatch,getState) {
      
      if(city === 'Secunderabad'){
        city = 'Hyderabad';
      }
      if(city === "Bangalore" || city==="Bengaluru Rural" || city==="Bengaluru Urban" || city === "Bangalore Rural"|| city==="Bangalore Urban"){
        city = "Bengaluru";
      }
      let self = this;
      let url = urls.locationByLatLongUrl;
      let input = {name : city};
      axios.post(url,input)
        .then(function(response) {
          if(response.data.statusCD === 200) {
            
            let result = response.data.responseData;
            dispatch(updatePickupCity({city:city,cityLat : result.latitude,cityLng:result.longitude, cityLeadTime : result.leadTime}));
            dispatch(updateCityOfStop({city:city,cityLat : result.latitude,cityLng:result.longitude, cityLeadTime : result.leadTime},0));
          }
          if(response.data.statusCD === 404) {
            dispatch(updatePickupCity({city:city,cityLat : '',cityLng:'', cityLeadTime : ''}));
          }
        })
        .catch(function(error) {
          dispatch(updatePickupCity({city:city,cityLat : '',cityLng:'', cityLeadTime : ''}));
        });
    }
  }