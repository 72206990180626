import React from 'react';
import { css } from 'emotion';
import SliderModal from './SliderModal';
import DropDown from 'react-dropdown';
import { navigate } from "gatsby";

/*global tw*/

class ZoomLocationSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            location: null
        }
    }

    render() {
        return (
            <SliderModal active={this.props.active}>
                <div className={css`${tw`relative flex flex-wrap items-center lg:px-12 px-6 py-4 pb-24 max-h-full w-full overflow-y-scroll`}; -webkit-overflow-scrolling: touch;`}>
                    <p className={css`${tw`lg:text-xl text-md font-bold text-grey-dark py-4`}`}>Select Vehicle Pickup &amp; Dropoff address</p>
                    <DropDown
                        options={this.props.locations}
                        value={this.state.label}
                        onChange={(event) => {
                            console.log("Locations Event", event);
                            this.setState({ location: event, label: event.label })
                        }}
                        placeholder="Pickup Location"
                        className={css`${tw`w-full mt-4 font-medium text-xs lg:text-md`};`}
                    />
                    <div className={css`${tw`w-full my-4 flex lg:justify-end items-center`}`}>
                        <button onClick={() => { navigate(`/carsList`) }} className={css`${tw`w-full lg:w-1/4 px-4 py-4 mt-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-transparent hover:bg-grey-light rounded-md lg:mr-2 border-none`}`}>Back</button>
                        <button onClick={() => this.props.handleZoomLocationSelect(this.state.location)} className={css`${tw`w-full lg:w-1/4 px-4 py-4 mt-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-grey-light rounded-md hover:bg-white hover:shadow-lg border-none`}`}>Proceed</button>
                    </div>
                </div>
            </SliderModal>
        )
    }
}

export default ZoomLocationSlider;